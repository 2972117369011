import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_LEAGUE,
    DELETE_LEAGUE,
    LEAGUE_FORM_TOOGLE_LOADING,
    SET_LEAGUE_LIST,
    SHOW_NOTIFICATION,
    UPDATE_LEAGUE,
} from "../constants";
import { formatLeagues } from "./settingsActionsUtils";

/* LEAGUE LIST */
export const fetchLeagues = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.leagues.list)
            .then((response) => {
                const leagues = formatLeagues(response.data);
                dispatch({
                    type: SET_LEAGUE_LIST,
                    payload: keyBy(leagues, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// LEAGUES
export const createLeague = (newLeague) => {
    return async (dispatch) => {
        dispatch({ type: LEAGUE_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.leagues.create, newLeague)
            .then((response) => {
                const league = formatLeagues(response.data);
                dispatch({ type: ADD_NEW_LEAGUE, payload: league });
                dispatch({ type: LEAGUE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Lliga creada amb éxit.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: LEAGUE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateLeague = (updatedLeague) => {
    return async (dispatch) => {
        dispatch({ type: LEAGUE_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.leagues.edit}${updatedLeague && updatedLeague._id}`, updatedLeague)
            .then((response) => {
                const league = formatLeagues(response.data);
                dispatch({ type: UPDATE_LEAGUE, payload: league });
                dispatch({ type: LEAGUE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Lliga actualitzada.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: LEAGUE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteLeagues = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: LEAGUE_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.leagues.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_LEAGUE, payload: Ids });
                dispatch({ type: LEAGUE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Lliga eliminada.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: LEAGUE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const API = {
	leagueTables: {
    create: `/leagueTable`,
    edit: `/leagueTable/`,
    delete: `/leagueTable/`,
    list: `/leagueTable`,
  },
	matchs: {
    create: `/match`,
    edit: `/match/`,
    delete: `/match/`,
    list: `/match`,
  },
	couples: {
    create: `/couple`,
    edit: `/couple/`,
    delete: `/couple/`,
    list: `/couple`,
  },
	leagues: {
    create: `/league`,
    edit: `/league/`,
    delete: `/league/`,
    list: `/league`,
  },
	players: {
    create: `/player`,
    edit: `/player/`,
    delete: `/player/`,
    list: `/player`,
  },
  auth: {
    login: `/user/login`,
    check2FA: `/user/check2FA`,
  },
  users: {
    profile: `/user/profile`,
    create: `/user/register/`,
    edit: `/user/`,
    delete: `/user/`,
    list: `/user`,
  },
  alerts: {
    create: `/alert`,
    edit: `/alert/`,
    delete: `/alert/`,
    list: `/alert`,
  },
};

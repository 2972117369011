import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_LEAGUETABLE,
    DELETE_LEAGUETABLE,
    LEAGUETABLE_FORM_TOOGLE_LOADING,
    SET_LEAGUETABLE_LIST,
    SHOW_NOTIFICATION,
    UPDATE_LEAGUETABLE,
} from "../constants";
import { formatLeagueTables } from "./settingsActionsUtils";

/* LEAGUETABLE LIST */
export const fetchLeagueTables = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.leagueTables.list)
            .then((response) => {
                const leagueTables = formatLeagueTables(response.data);
                dispatch({
                    type: SET_LEAGUETABLE_LIST,
                    payload: keyBy(leagueTables, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// LEAGUETABLES
export const createLeagueTable = (newLeagueTable) => {
    return async (dispatch) => {
        dispatch({ type: LEAGUETABLE_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.leagueTables.create, newLeagueTable)
            .then((response) => {
                const leagueTable = formatLeagueTables(response.data);
                dispatch({ type: ADD_NEW_LEAGUETABLE, payload: leagueTable });
                dispatch({ type: LEAGUETABLE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "TaulaClasificacion creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: LEAGUETABLE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateLeagueTable = (updatedLeagueTable) => {
    return async (dispatch) => {
        dispatch({ type: LEAGUETABLE_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.leagueTables.edit}${updatedLeagueTable && updatedLeagueTable._id}`, updatedLeagueTable)
            .then((response) => {
                const leagueTable = formatLeagueTables(response.data);
                dispatch({ type: UPDATE_LEAGUETABLE, payload: leagueTable });
                dispatch({ type: LEAGUETABLE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "TaulaClasificacion actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: LEAGUETABLE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteLeagueTables = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: LEAGUETABLE_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.leagueTables.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_LEAGUETABLE, payload: Ids });
                dispatch({ type: LEAGUETABLE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "TaulaClasificacion eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: LEAGUETABLE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_MATCH,
    DELETE_MATCH,
    MATCH_FORM_TOOGLE_LOADING,
    SET_MATCH_LIST,
    SHOW_NOTIFICATION,
    UPDATE_MATCH,
} from "../constants";
import { formatMatchs } from "./settingsActionsUtils";

/* MATCH LIST */
export const fetchMatchs = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.matchs.list)
            .then((response) => {
                const matchs = formatMatchs(response.data);
                dispatch({
                    type: SET_MATCH_LIST,
                    payload: keyBy(matchs, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// MATCHS
export const createMatch = (newMatch) => {
    return async (dispatch) => {
        dispatch({ type: MATCH_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.matchs.create, newMatch)
            .then((response) => {
                const match = formatMatchs(response.data);
                dispatch({ type: ADD_NEW_MATCH, payload: match });
                dispatch({ type: MATCH_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Partit creat amb éxit.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: MATCH_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateMatch = (updatedMatch) => {
    return async (dispatch) => {
        dispatch({ type: MATCH_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.matchs.edit}${updatedMatch && updatedMatch._id}`, updatedMatch)
            .then((response) => {
                const match = formatMatchs(response.data);
                dispatch({ type: UPDATE_MATCH, payload: match });
                dispatch({ type: MATCH_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Partit actualitzat.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: MATCH_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteMatchs = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: MATCH_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.matchs.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_MATCH, payload: Ids });
                dispatch({ type: MATCH_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Partit eliminat.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: MATCH_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

import moment from "moment";
import { isArray, isObject } from "lodash";

export const formatLeagueTables = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
    };
  }
};
export const formatMatchs = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
      matchDate: data.matchDate && moment(data.matchDate),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
      matchDate: data.matchDate && moment(data.matchDate),
    };
  }
};
export const formatCouples = (data, state) => {
  if (!data) {
    return [];
  }
  if(!state){
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${state.players[data.playerOne].label} - ${state.players[data.playerTwo].label}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${state.players[data.playerOne].label} - ${state.players[data.playerTwo].label}`,
    };
  }
};
export const formatLeagues = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
    };
  }
};
export const formatPlayers = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name} ${data.surname ? data.surname : ""}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name} ${data.surname ? data.surname : ""}`,
    };
  }
};
export const formatUsers = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name} ${data.surname}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name} ${data.surname}`,
    };
  }
};

export const formatAlerts = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.title}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.title}`,
    };
  }
};

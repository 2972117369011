/* BASIC APP CONSTANTS */
export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT";
export const APP_COOKIE_NAME = "APP_COOKIE_NAME";
export const USER_COOKIE_NAME = "USER_COOKIE_NAME";
export const PASS_COOKIE_NAME = "PASS_COOKIE_NAME";
export const USER_ID_COOKIE_NAME = "USER_ID_COOKIE_NAME";
export const SET_CURRENT_ROUTE = "SET_CURRENT_ROUTE";
export const SET_SIDE_MENU_STATE = "SET_SIDE_MENU_STATE";
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";
export const SHOW_ACTION_MENU = "SHOW_ACTION_MENU";
export const HIDE_ACTION_MENU = "HIDE_ACTION_MENU";
export const SHOW_BOTTOM_TOOLBAR = "SHOW_BOTTOM_TOOLBAR";
export const HIDE_BOTTOM_TOOLBAR = "HIDE_BOTTOM_TOOLBAR";
export const SET_USER_LIST = "SET_USER_LIST";
export const EDIT_DISABLED_BUTTONS = "EDIT_DISABLED_BUTTONS";
export const SET_LOGS_LIST = "SET_LOGS_LIST";
export const THEME = "THEME";

/* <---- BASIC APP CONSTANTS ----> */

/* SOCKET CONSTANTS */
export const CONNECTED = "connected";
export const DISCONNECTED = "disconnected";
/* <---- SOCKET CONSTANTS ----> */

/* CONFIGURATION */
export const CONFIGURATION_FORM_TOOGLE_LOADING =
  "CONFIGURATION_FORM_TOOGLE_LOADING";
export const SET_CONFIGURATION = "SET_CONFIGURATION";
/* <---- CONFIGURATION ----> */

/* USER */
export const SET_PROFILE = "SET_PROFILE";
/* <---- USER ----> */

/* USERS */
export const ADD_NEW_USER = "ADD_NEW_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const OPEN_USERS_FORM = "OPEN_USERS_FORM";
export const CLOSE_USERS_FORM = "CLOSE_USERS_FORM";
export const EDIT_SELECTED_USER = "EDIT_SELECTED_USER";
export const USERS_FORM_TOOGLE_LOADING = "USERS_FORM_TOOGLE_LOADING";
/* <---- USERS ----> */

/* LEAGUETABLE */
export const SET_LEAGUETABLE_LIST = "SET_LEAGUETABLE_LIST";
export const ADD_NEW_LEAGUETABLE = "ADD_NEW_LEAGUETABLE";
export const UPDATE_LEAGUETABLE = "UPDATE_LEAGUETABLE";
export const DELETE_LEAGUETABLE = "DELETE_LEAGUETABLE";
export const OPEN_LEAGUETABLE_FORM = "OPEN_LEAGUETABLE_FORM";
export const CLOSE_LEAGUETABLE_FORM = "CLOSE_LEAGUETABLE_FORM";
export const EDIT_SELECTED_LEAGUETABLE = "EDIT_SELECTED_LEAGUETABLE";
export const LEAGUETABLE_FORM_TOOGLE_LOADING = "LEAGUETABLE_FORM_TOOGLE_LOADING";
/* <---- LEAGUETABLE ----> */

/* MATCH */
export const SET_MATCH_LIST = "SET_MATCH_LIST";
export const ADD_NEW_MATCH = "ADD_NEW_MATCH";
export const UPDATE_MATCH = "UPDATE_MATCH";
export const DELETE_MATCH = "DELETE_MATCH";
export const OPEN_MATCH_FORM = "OPEN_MATCH_FORM";
export const CLOSE_MATCH_FORM = "CLOSE_MATCH_FORM";
export const EDIT_SELECTED_MATCH = "EDIT_SELECTED_MATCH";
export const MATCH_FORM_TOOGLE_LOADING = "MATCH_FORM_TOOGLE_LOADING";
/* <---- MATCH ----> */

/* COUPLE */
export const SET_COUPLE_LIST = "SET_COUPLE_LIST";
export const ADD_NEW_COUPLE = "ADD_NEW_COUPLE";
export const UPDATE_COUPLE = "UPDATE_COUPLE";
export const DELETE_COUPLE = "DELETE_COUPLE";
export const OPEN_COUPLE_FORM = "OPEN_COUPLE_FORM";
export const CLOSE_COUPLE_FORM = "CLOSE_COUPLE_FORM";
export const EDIT_SELECTED_COUPLE = "EDIT_SELECTED_COUPLE";
export const COUPLE_FORM_TOOGLE_LOADING = "COUPLE_FORM_TOOGLE_LOADING";
/* <---- COUPLE ----> */

/* LEAGUE */
export const SET_LEAGUE_LIST = "SET_LEAGUE_LIST";
export const ADD_NEW_LEAGUE = "ADD_NEW_LEAGUE";
export const UPDATE_LEAGUE = "UPDATE_LEAGUE";
export const DELETE_LEAGUE = "DELETE_LEAGUE";
export const OPEN_LEAGUE_FORM = "OPEN_LEAGUE_FORM";
export const CLOSE_LEAGUE_FORM = "CLOSE_LEAGUE_FORM";
export const EDIT_SELECTED_LEAGUE = "EDIT_SELECTED_LEAGUE";
export const LEAGUE_FORM_TOOGLE_LOADING = "LEAGUE_FORM_TOOGLE_LOADING";
/* <---- LEAGUE ----> */

/* PLAYER */
export const SET_PLAYER_LIST = "SET_PLAYER_LIST";
export const ADD_NEW_PLAYER = "ADD_NEW_PLAYER";
export const UPDATE_PLAYER = "UPDATE_PLAYER";
export const DELETE_PLAYER = "DELETE_PLAYER";
export const OPEN_PLAYER_FORM = "OPEN_PLAYER_FORM";
export const CLOSE_PLAYER_FORM = "CLOSE_PLAYER_FORM";
export const EDIT_SELECTED_PLAYER = "EDIT_SELECTED_PLAYER";
export const PLAYER_FORM_TOOGLE_LOADING = "PLAYER_FORM_TOOGLE_LOADING";
/* <---- PLAYER ----> */

/* ROUTES */
export const SET_ALL_ROUTES = "SET_ALL_ROUTES";
export const UPDATE_ROUTE = "UPDATE_ROUTE";
/* <---- ROUTES ----> */

/* ALERT */
export const SET_ALERT_LIST = "SET_ALERT_LIST";
export const ADD_NEW_ALERT = "ADD_NEW_ALERT";
export const UPDATE_ALERT = "UPDATE_ALERT";
export const DELETE_ALERT = "DELETE_ALERT";
export const OPEN_ALERT_FORM = "OPEN_ALERT_FORM";
export const CLOSE_ALERT_FORM = "CLOSE_ALERT_FORM";
export const EDIT_SELECTED_ALERT = "EDIT_SELECTED_ALERT";
export const ALERT_FORM_TOOGLE_LOADING = "ALERT_FORM_TOOGLE_LOADING";
/* <---- ALERT ----> */

import { combineReducers } from "redux";
import {
  authorization,
  profile,
  currentRoute,
  notifications,
  app,
  usersForm,
  users,
  alerts,
  alertsForm,
  currentTheme,
  players,
  playersForm,
  leagues,
  leaguesForm,
  couples,
  couplesForm,
  matchs,
  matchsForm,
  leagueTables,
  leagueTablesForm,
} from "./Reducers";

export default combineReducers({
  leagueTables,
  leagueTablesForm,
  matchs,
  matchsForm,
  couples,
  couplesForm,
  leagues,
  leaguesForm,
  players,
  playersForm,
  app,
  authorization,
  currentRoute,
  profile,
  notifications,
  usersForm,
  users,
  alerts,
  alertsForm,
  currentTheme,
});

import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_COUPLE,
    DELETE_COUPLE,
    COUPLE_FORM_TOOGLE_LOADING,
    SET_COUPLE_LIST,
    SHOW_NOTIFICATION,
    UPDATE_COUPLE,
} from "../constants";
import { formatCouples } from "./settingsActionsUtils";

/* COUPLE LIST */
export const fetchCouples = () => {
    return async (dispatch, useState) => {
        const response = await axios
            .get(API.couples.list)
            .then((response) => {
                const couples = formatCouples(response.data, useState());
                dispatch({
                    type: SET_COUPLE_LIST,
                    payload: keyBy(couples, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// COUPLES
export const createCouple = (newCouple) => {
    return async (dispatch, useState) => {
        dispatch({ type: COUPLE_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.couples.create, newCouple)
            .then((response) => {
                const couple = formatCouples(response.data, useState());
                dispatch({ type: ADD_NEW_COUPLE, payload: couple });
                dispatch({ type: COUPLE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Parella creada amb éxit.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: COUPLE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateCouple = (updatedCouple) => {
    return async (dispatch, useState) => {
        dispatch({ type: COUPLE_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.couples.edit}${updatedCouple && updatedCouple._id}`, updatedCouple)
            .then((response) => {
                const couple = formatCouples(response.data, useState());
                dispatch({ type: UPDATE_COUPLE, payload: couple });
                dispatch({ type: COUPLE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Parella actualitzada.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: COUPLE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteCouples = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: COUPLE_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.couples.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_COUPLE, payload: Ids });
                dispatch({ type: COUPLE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Parella eliminada.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: COUPLE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_PLAYER,
    DELETE_PLAYER,
    PLAYER_FORM_TOOGLE_LOADING,
    SET_PLAYER_LIST,
    SHOW_NOTIFICATION,
    UPDATE_PLAYER,
} from "../constants";
import { formatPlayers } from "./settingsActionsUtils";

/* PLAYER LIST */
export const fetchPlayers = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.players.list)
            .then((response) => {
                const players = formatPlayers(response.data);
                dispatch({
                    type: SET_PLAYER_LIST,
                    payload: keyBy(players, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// PLAYERS
export const createPlayer = (newPlayer) => {
    return async (dispatch) => {
        dispatch({ type: PLAYER_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.players.create, newPlayer)
            .then((response) => {
                const player = formatPlayers(response.data);
                dispatch({ type: ADD_NEW_PLAYER, payload: player });
                dispatch({ type: PLAYER_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Jugador creat amb éxit.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: PLAYER_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updatePlayer = (updatedPlayer) => {
    return async (dispatch) => {
        dispatch({ type: PLAYER_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.players.edit}${updatedPlayer && updatedPlayer._id}`, updatedPlayer)
            .then((response) => {
                const player = formatPlayers(response.data);
                dispatch({ type: UPDATE_PLAYER, payload: player });
                dispatch({ type: PLAYER_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Jugador actualitzat.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: PLAYER_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deletePlayers = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: PLAYER_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.players.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_PLAYER, payload: Ids });
                dispatch({ type: PLAYER_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Jugador eliminat.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: PLAYER_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
